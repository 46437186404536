<script setup>
import { useCaptchaManager } from '@0x234/common-vue/composables/captcha-manager';
import { useCountdownManager } from '@0x234/common-vue/composables/countdown-manager';
import { useNotifyManager } from '@0x234/common-vue/composables/notify-manager';
import { OtpError } from '@0x234/common-vue/errors/otp-error';
import AppLogo from 'components/AppLogo.vue';
import AppTerms from 'components/AppTerms.vue';
import ContributionAnimationWallpaper from 'components/ContributionAnimationWallpaper.vue';
import CaptchaChallengeInput from 'components/form-input/CaptchaChallengeInput.vue';
import SocialLogin from 'components/SocialLogin.vue';
import { useMeta, useQuasar } from 'quasar';
import { useOtpManager } from 'src/composables/otp-manager';
import AppRoute from 'src/router/app-route';
import { Route } from 'src/router/routes';
import { useAuthStore } from 'stores/auth-store';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const { message: errorMessage, isCountdownActive, initCountdown, resetCountdown } = useCountdownManager();
const { challengeInputRef, challengeModel, challengeRequest, isChallengeRequired, handleCaptchaChallenge, resetChallengeState } = useCaptchaManager();

const $q = useQuasar();
const $router = useRouter();
const authStore = useAuthStore();
const notifyManager = useNotifyManager();
const otpManager = useOtpManager();

const testRef = ref('false');

const formElement = ref();
const formModel = ref({
    email: null
});

async function onFormSubmit() {
    testRef.value = 'true';
    resetCountdown();
    $q.loading.show();
    await handleCaptchaChallenge(
        async captcha => {
            const token = await authStore.requestLoginOtp(formModel.value.email, captcha);
            otpManager.openOtpDialog(token, formModel.value.email).onOk(() => {
                $router.push(AppRoute.build(Route.Dashboard));
            });
            resetChallengeState();
        },
        err => {
            if (err instanceof OtpError && err.details.retryAfter) {
                initCountdown(err.details.retryAfter, 'Too many attempts, please try again in');
                return;
            }
            if (err?.response?.status === 403) {
                $router.push(AppRoute.build(Route.Dashboard));
                notifyManager.notifyWarning('You are already logged in!');
                return;
            }
            errorMessage.value = 'Something went wrong, please restart the login process.';
        },
        () => {
            $q.loading.hide();
        }
    );
}

useMeta({
    title: 'Login',
    meta: {
        description: {
            name: 'description',
            content: 'Log in to Xprt to access your professional engineering profile, connect with peers, and discover new opportunities. Manage your digital presence in a '
                + 'community dedicated to software engineering excellence.'
        },
        ogTitle: {
            property: 'og:title',
            content: 'Login'
        },
        ogDescription: {
            property: 'og:description',
            content: 'Enter your Xprt account to continue shaping your digital engineering portfolio, engage with like-minded professionals, and explore a world of opportunities '
                + 'tailored for software engineers.'
        },
        ogSiteName: {
            property: 'og:site_name',
            content: 'xprt.dev'
        },
        ogType: {
            property: 'og:type',
            content: 'website'
        },
        ogImage: {
            property: 'og:image',
            content: 'https://xprt.syd1.cdn.digitaloceanspaces.com/pub/xprt-icon.png'
        }
    }
});
</script>

<template>
    <q-page id="login-page" class="flex column justify-center q-py-lg">
        <div class="page-wrapper">
            <ContributionAnimationWallpaper/>
        </div>
        <div class="top-spacer"></div>
        <div class="login-container">
            <AppLogo class="q-mx-auto q-my-lg"/>
            <q-card class="login-card no-shadow q-mx-auto q-my-xl q-pt-md q-pb-md text-center">
                <q-card-section>
                    <p class="text-h2 text-weight-medium q-mt-md">Login</p>
                </q-card-section>
                <q-card-section class="q-pb-sm">
                    <p class="text-h6 font-body text-weight-medium q-mb-sm">Don't have an account?</p>
                    <p class="q-mb-none">Simply enter your email and log in, or choose a preferred service to get started.</p>
                </q-card-section>
                <q-card-section>
                    <div v-if="errorMessage" class="login-error text-negative text-weight-medium q-mb-lg">{{ errorMessage }}</div>
                    <div class="email-form form-container q-mx-auto">
                        <q-form ref="formElement" @submit.prevent="onFormSubmit" greedy>
                            <div class="q-mb-md">
                                <q-input
                                    v-model="formModel.email"
                                    :readonly="isChallengeRequired"
                                    :rules="[ v => !!v || 'Please enter your email' ]"
                                    label="Email"
                                    type="email"
                                    lazy-rules
                                    filled
                                    bottom-slots
                                />
                            </div>
                            <div v-if="isChallengeRequired" class="q-mb-md">
                                <CaptchaChallengeInput ref="challengeInputRef" v-model="challengeModel" :captcha-request="challengeRequest"/>
                            </div>
                            <q-btn :disable="isCountdownActive" label="Log in with Email" type="submit" color="primary" unelevated/>
                        </q-form>
                    </div>
                </q-card-section>
                <q-card-section>
                    <p class="text-subtitle2 text-weight-bold q-mb-lg">
                        &mdash; <span class="q-mx-sm">Or log in with</span> &mdash;
                    </p>
                    <SocialLogin :hide-strategies="['email']"/>
                </q-card-section>
            </q-card>
            <AppTerms inverted/>
        </div>
    </q-page>
</template>

<style lang="scss" scoped>
#login-page {
    position: relative;
}

.page-wrapper {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: -1;
}

.email-form {
    margin: 0 auto;
    max-width: 350px;
}

.login-container {
    align-self: center;
    flex: 3 1 60%;
    text-align: center;
    width: 100%;
}

.q-card {
    background: rgba($white, 0.9);
    border-left: 0;
    border-right: 0;

    .body--dark & {
        background: rgba($dark-page, 0.9);
    }
}

.top-spacer {
    flex: 2 1 40%;
}

.app-logo {
    height: 52px !important;
}
</style>
